import axios from 'axios';

export function GetMeta(name, attribute = 'content') {
    return document.querySelector(`meta[name='${name}']`).getAttribute(attribute)
}

export function GetCSRFObject() {
    let o = {};
    o[GetMeta('csrf-param')] = GetMeta('csrf-token');
    return o;
}

export const SecureFetch = (url, options, includeCSRF = true) => {
    let headers = {}
    if (includeCSRF) {
        headers["X-CSRF-Token"] = GetMeta('csrf-token');
    }

    let body = undefined;

    if (options.useFormData) {
        headers["Content-Type"] = "multipart/form-data"
        let fd = new FormData();
        fd.append(undefined, JSON.stringify(options.data))
        body = fd
    } else if (options.data) {
        headers["Content-Type"] = "application/json"
        body = JSON.stringify(options.data)
    }

    if (options.withJSON) {
        headers["Accept"] = "application/json"
    }
    if (options.withHTML) {
        headers["Accept"] = "text/html"
    }

    // let f = fetch(url, {
    //     method: options.method || "POST",
    //     headers:  headers,
    //     body: body
    // })
    //
    // if (options.withJSON){
    //     f.then(response => response.json()).then(options.withJSON)
    // }
    // if (options.withHTML){
    //     f.then(response => response.text()).then(options.withHTML)
    // }

    let f = accept => fetch(url, {
        method: options.method || "POST",
        headers: Object.assign(headers, { "Accept": accept }),
        body: body
    })
    //
    // if (options.withJSON){
    //     f.then(response => response.json()).then(options.withJSON)
    // }
    // if (options.withHTML){
    //     f.then(response => response.text()).then(options.withHTML)
    // }

    return {
        withJSON: callback => f("application/json").then(response => response.json()).then(callback),
        withHTML: callback => f("text/html").then(response => response.text()).then(callback)
    }


}


export function FetchJSON(url, data, method = 'POST', include_csrf = true) {

    let headers = {
        "Content-Type": "application/json",
        "Accept": "application/json"
    }

    if (include_csrf) {
        headers["X-CSRF-Token"] = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    }

    return fetch(url, {
        method: method,
        headers: headers,
        body: data === undefined ? undefined : JSON.stringify(data)
    })
}

export function FetchJSONResolved(url, data, method = 'POST', include_csrf = true) {

    return new Promise((resolve, reject) => {
        FetchJSON(url, data, method, include_csrf).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                reject();
            }
        }).then(data => {
            resolve(data);
        })
    });
}


export function FetchHTML(url, data, method = 'GET', include_csrf = true) {

    let headers = {
        "Content-Type": "application/json",
        "Accept": "text/html"
    }

    if (include_csrf) {
        headers["X-CSRF-Token"] = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    }

    return fetch(url, {
        method: method,
        headers: headers,
        body: data === undefined ? undefined : JSON.stringify(data)
    })
}


export function GetURLParam(key) {
    return new URLSearchParams(window.location.search).get(key);
}


const VariableAPI = axios.create();
VariableAPI.defaults.headers.common['Content-Type'] = 'application/json';
VariableAPI.defaults.headers.common['Accept'] = 'application/json';
VariableAPI.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").getAttribute("content");
VariableAPI.defaults.params = {};
VariableAPI.defaults.params["_timestamp"] = new Date().getTime();
export { VariableAPI }